.custom-table_wrapper .custom-table_container {
  margin-bottom: 1rem; }

.custom-table_wrapper .table-link-title {
  color: #000; }

.custom-table_wrapper .table-icon {
  margin-right: 5px; }

.custom-table_wrapper .custom-table_subtable {
  margin: 1rem 0 0 0; }

.custom-table_wrapper .custom-table_head {
  border-radius: 2px 0 2px 0;
  -moz-border-radius: 2px 0 2px 0;
  -webkit-border-radius: 2px 0 2px 0; }

.custom-table_wrapper .custom-table_header {
  background-color: #23232e; }
  .custom-table_wrapper .custom-table_header .MuiTableCell-root {
    color: #ececec; }

.custom-table_wrapper .MuiTableCell-root {
  padding: 0.5rem;
  font-size: 0.750rem; }

img.icon-table {
  max-width: 15%; }

img.icon-table2 {
  max-width: 30%; }

td.hideCell {
  display: none; }

tr.MuiTableRow-root.custom-table_rows.selected {
  background-color: aqua; }

tr.MuiTableRow-root.custom-table_rows.new {
  background-color: yellow; }
