.form-group-windowSearch {
  margin-left: 0rem; }

.positionButton {
  margin-left: 6rem;
  margin-top: 2rem; }

.custom-margin {
  margin-left: 2rem; }

.form-searh-table .positionButton .btn-second {
  color: #000;
  background: #b0adad;
  border: 1px solid #b0adad;
  margin: 2px; }

.form-searh-table .positionButton .btn-second:hover {
  background: #d2cfcf;
  border: 1px solid #d2cfcf; }

.form-searh-table .positionButton .btn-princ {
  color: #fff;
  background: #0288d1;
  border: 1px solid #0288d1;
  margin: 2px; }

.form-searh-table .positionButton .btn-princ:hover {
  background: #5b92af;
  border: 1px solid #5b92af; }

.form-searh-table .positionButton .MuiButton-root .MuiButton-label {
  font-family: sans-serif; }
