.center {
  text-align: center; }

.pieChart[data-title] {
  position: relative;
  cursor: help; }

.pieChart[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: -88px;
  left: 311px;
  display: inline-block;
  padding: 7px 4px;
  border-radius: 2px;
  background: #170949;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: pre-wrap;
  width: 156px; }

.pieChart[data-title]:hover::after {
  position: absolute;
  bottom: -10px;
  left: 8px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-bottom: 8px solid #000; }
