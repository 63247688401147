/* .customTextSize {
  font-size: 12px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-right: 0rem !important;
  padding-left: 4px !important; } */

  .p-datatable .p-datatable-tbody > tr > td, .p-datatable .p-datatable-thead > tr > th
   {
    text-align: center!important;
    border: 0.5px solid #e9ecef!important;
    border-width: 0 1px 1px 0!important;
    padding: 0rem 0rem!important;
  }   