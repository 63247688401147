.form-custom-group {
  display: flex;
  justify-content: space-between; }
  .form-custom-group .label-name {
    display: none; }
  .form-custom-group .label-name-show {
    display: inline-block; }
  .form-custom-group .custom-input {
    width: 100%; }

.form-custom-group-edit {
  display: block;
  justify-content: space-between; }
  .form-custom-group-edit .label-name {
    display: none; }
  .form-custom-group-edit .label-name-show {
    display: inline-block; }
  .form-custom-group-edit .custom-input {
    width: 100%; }

.displayNone {
  display: none; }
