img.little-icon {
  max-width: 20%; }

img.icon-table {
  max-width: 2rem; }

img.icon-table2 {
  max-width: 1rem; }

.react-switch {
  vertical-align: middle;
  margin-left: 4px; }

.outgoing_msg .det_list:before {
  display: block;
  content: '\1F518';
  width: 20px;
  float: left; }

.outgoing_msg .card-quick_reply .received_withd_msg {
  max-width: 60%;
  float: right; }

.outgoing_msg .list, .outgoing_msg .quick_reply {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%; }
  .outgoing_msg .list .titulo_list, .outgoing_msg .list .titulo_quick, .outgoing_msg .list .option-title, .outgoing_msg .quick_reply .titulo_list, .outgoing_msg .quick_reply .titulo_quick, .outgoing_msg .quick_reply .option-title {
    font-weight: bold; }
  .outgoing_msg .list .opciones_list, .outgoing_msg .list .subtitulo_quick, .outgoing_msg .list .buttons_quick, .outgoing_msg .quick_reply .opciones_list, .outgoing_msg .quick_reply .subtitulo_quick, .outgoing_msg .quick_reply .buttons_quick {
    margin-top: 5px;
    padding-left: 15px;
    font-weight: bold; }
    .outgoing_msg .list .opciones_list .option-subtitle, .outgoing_msg .list .subtitulo_quick .option-subtitle, .outgoing_msg .list .buttons_quick .option-subtitle, .outgoing_msg .quick_reply .opciones_list .option-subtitle, .outgoing_msg .quick_reply .subtitulo_quick .option-subtitle, .outgoing_msg .quick_reply .buttons_quick .option-subtitle {
      font-weight: 400; }
    .outgoing_msg .list .opciones_list .det_list, .outgoing_msg .list .opciones_list .button_quick, .outgoing_msg .list .subtitulo_quick .det_list, .outgoing_msg .list .subtitulo_quick .button_quick, .outgoing_msg .list .buttons_quick .det_list, .outgoing_msg .list .buttons_quick .button_quick, .outgoing_msg .quick_reply .opciones_list .det_list, .outgoing_msg .quick_reply .opciones_list .button_quick, .outgoing_msg .quick_reply .subtitulo_quick .det_list, .outgoing_msg .quick_reply .subtitulo_quick .button_quick, .outgoing_msg .quick_reply .buttons_quick .det_list, .outgoing_msg .quick_reply .buttons_quick .button_quick {
      font-weight: 400;
      padding-left: 10px;
      background: #ebebeb none repeat scroll 0 0;
      border-radius: 3px;
      color: #646464;
      font-size: 14px;
      margin: 0;
      padding: 5px 10px 5px 12px;
      width: auto;
      margin-bottom: 8px; }
    .outgoing_msg .list .opciones_list .det_list, .outgoing_msg .list .subtitulo_quick .det_list, .outgoing_msg .list .buttons_quick .det_list, .outgoing_msg .quick_reply .opciones_list .det_list, .outgoing_msg .quick_reply .subtitulo_quick .det_list, .outgoing_msg .quick_reply .buttons_quick .det_list {
      max-width: 90%; }
    .outgoing_msg .list .opciones_list .button_quick, .outgoing_msg .list .subtitulo_quick .button_quick, .outgoing_msg .list .buttons_quick .button_quick, .outgoing_msg .quick_reply .opciones_list .button_quick, .outgoing_msg .quick_reply .subtitulo_quick .button_quick, .outgoing_msg .quick_reply .buttons_quick .button_quick {
      max-width: 80%;
      border-radius: 10px; }

.tableTransfers {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 9px; }

.tableTransfers .p-datatable-header {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2px;
  font-family: 'Circular Std Medium'; }

.tableTransfers .p-column-title {
  font-weight: 700;
  font-size: 11px; }

.tableTransfers .p-datatable-tbody {
  font-size: 11px;
  font-weight: 450; }

.tableTransfers .p-datatable-thead tr th {
  background: #ffff !important; }

.tableTransfers .p-datatable .p-datatable-tbody tr td {
  border: 0.5px solid #b8c5d2 !important; }

.tableTransfers .p-datatable .p-datatable-thead tr th {
  border: 0.5px solid #b8c5d2 !important; }

.tabsMenuInfo span.p-menuitem-text {
  display: none !important; }

.tabsMenuInfo span.p-menuitem-icon {
  margin-right: 0px !important; }

.tabsMenuInfo .p-menuitem-link {
  padding: 0.5rem !important; }

.tabsMenuInfo .p-tabmenu-nav {
  display: inline-flex; }

.headerTab {
  text-align: center; }

.fixed-buttonNotas {
  margin-top: 0rem;
  margin-left: 16rem;
  margin-bottom: 1rem; }

#notasOcultasmodal .p-dialog-header {
  padding-bottom: 0rem; }

#notasOcultasmodal .p-dialog-content {
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem; }

.bodyNotasCard .p-card-subtitle {
  text-align: left;
  font-weight: bold;
  margin-bottom: 0.2rem;
  color: #6c757d;
  font-size: 10px;
  font-style: italic; }

.bodyNotasCard .p-card-content {
  padding-top: 0rem !important;
  padding-bottom: 0.2rem !important;
  font-size: 11px; }

.bodyNotasCard .notaAutor {
  text-align: right;
  font-size: 11px;
  font-style: italic; }

.btnAcciones {
  position: fixed;
  top: 80px;
  right: 50px;
  z-index: 999;
  padding-bottom: 50px;
  background-color: azure;
  padding: 4px;
  margin: 5px; }

.cardInfoConv {
  min-height: 50px; }
