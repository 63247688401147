.side-nav-wrapper {
  position: fixed;
  /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  width: 0;
  height: 100vh;
  background-color: #23232e;
  transition: width 200ms ease;
  z-index: 3; }
  .side-nav-wrapper .side-nav-nar {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 70px;
    display: none; }
    .side-nav-wrapper .side-nav-nar .logo {
      text-align: center;
      color: #ececec;
      background-color: #141418;
      text-transform: uppercase;
      letter-spacing: 0.3ch;
      width: 100%; }
      .side-nav-wrapper .side-nav-nar .logo .link-text {
        margin-left: 1rem;
        font-weight: bold;
        font-size: 1.5rem; }
      .side-nav-wrapper .side-nav-nar .logo .nav-icon {
        transform: rotate(0deg);
        transition: transform 600ms; }
    .side-nav-wrapper .side-nav-nar:hover .logo .nav-icon {
      transform: rotate(-180deg); }
  .side-nav-wrapper.activated {
    width: 20rem; }
    .side-nav-wrapper.activated .side-nav-nar {
      display: block; }
      .side-nav-wrapper.activated .side-nav-nar .link-text {
        display: block; }
        @media only screen and (max-width: 780px) {
          .side-nav-wrapper.activated .side-nav-nar .link-text {
            display: none; } }
    @media only screen and (max-width: 780px) {
      .side-nav-wrapper.activated {
        width: 3rem; } }
  @media only screen and (min-width: 900px) {
    .side-nav-wrapper {
      top: 0;
      height: 100vh; }
      .side-nav-wrapper.activated {
        width: 20rem; }
        .side-nav-wrapper.activated .link-text {
          display: inline; } }
  @media only screen and (max-width: 780px) {
    .side-nav-wrapper {
      bottom: 0;
      width: 100vw;
      height: 5rem;
      z-index: 3; }
      .side-nav-wrapper .logo {
        display: none; }
      .side-nav-wrapper .side-nav-nar {
        flex-direction: row;
        justify-content: space-around; }
      .side-nav-wrapper .sidebar-nav-link {
        justify-content: center;
        margin-right: 20px; } }

.styled-nav-item {
  width: 100%;
  /* width must be same size as NavBar to center */
  text-align: center;
  /* Aligns <a> inside of NavIcon div */ }
  .styled-nav-item:hover > .submenu {
    display: block;
    transition: all 2s linear; }
  .styled-nav-item .nav-link {
    padding: 0; }
  .styled-nav-item:hover {
    background: #141418;
    color: #ff7eee; }
  .styled-nav-item:last-child {
    margin-top: auto; }
  .styled-nav-item .sidebar-nav-link {
    display: flex;
    align-items: center;
    height: 5rem;
    width: 16rem;
    color: #6649b8;
    text-decoration: none;
    filter: grayscale(100%) opacity(0.7);
    transition: 600ms; }
    .styled-nav-item .sidebar-nav-link:hover {
      text-decoration: none;
      filter: none; }
    .styled-nav-item .sidebar-nav-link .link-text {
      display: none;
      margin-left: 0;
      font-weight: 500;
      font-size: 1.2rem; }
    .styled-nav-item .sidebar-nav-link .nav-icon, .styled-nav-item .sidebar-nav-link .nav-image {
      min-width: 2rem;
      margin: 0 1.5rem;
      height: 2rem;
      color: #6649b8;
      transition: 600ms; }
      .styled-nav-item .sidebar-nav-link .nav-icon:hover, .styled-nav-item .sidebar-nav-link .nav-image:hover {
        color: #6649b8; }
    @media only screen and (max-width: 780px) {
      .styled-nav-item .sidebar-nav-link {
        width: auto; } }
  @media only screen and (max-width: 780px) {
    .styled-nav-item {
      width: auto; } }
  .styled-nav-item .submenu {
    display: none;
    transition: all 2s linear;
    padding-left: 0; }
    @media only screen and (max-width: 780px) {
      .styled-nav-item .submenu {
        display: none; } }
    .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link {
      height: 3rem;
      border-bottom: 1px solid #23232e; }
      .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link .link-text {
        font-size: 1rem; }
      .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link .nav-icon {
        max-width: 1.5rem;
        min-width: 1.5rem; }
    .styled-nav-item .submenu .submenu-nav-link {
      width: 20rem;
      background: #574b90;
      color: #eee;
      text-align: left;
      border-bottom: 1px solid #000;
      font-weight: 500;
      padding: 10px 0 10px 25px;
      filter: grayscale(30%) opacity(0.7); }
      .styled-nav-item .submenu .submenu-nav-link:hover {
        filter: grayscale(0%) opacity(1); }

.overlay {
  display: none;
  width: 100vw;
  height: 100vw;
  background: #000;
  position: fixed;
  top: 0;
  z-index: 2;
  left: 0;
  opacity: 0.4; }
  .overlay.activated {
    display: block; }
    @media only screen and (max-width: 780px) {
      .overlay.activated {
        display: none; } }
  @media only screen and (max-width: 780px) {
    .overlay {
      display: none; } }

.header_container {
  position: fixed;
  top: 0;
  right: auto;
  left: 0;
  margin-left: 0;
  width: 100%;
  margin-right: 0; }

.navbar-logout {
  border: none;
  background: transparent; }

.modal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .modal-wrapper .modal-background {
    background-color: #000;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5000;
    width: 100vw;
    height: 100vh; }
  .modal-wrapper .modal-content {
    z-index: 5001;
    width: 400px;
    height: 250px;
    background-color: #eee;
    display: flex;
    justify-content: space-around;
    position: relative; }
  .modal-wrapper .button-group {
    display: flex;
    justify-content: space-around; }
    .modal-wrapper .button-group button {
      width: 120px; }
  .modal-wrapper .modal-text {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem; }
  .modal-wrapper .modal-text2 {
    text-align: center;
    font-weight: lighter;
    font-size: 1rem; }
  .modal-wrapper .modal-info {
    width: 90%;
    margin: 0 auto; }
  .modal-wrapper .close-button {
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    left: auto;
    top: 0;
    right: 0;
    border: none;
    color: #bbb;
    padding: 0;
    line-height: 1; }

.sidenav-botonera a {
  margin: auto 5px; }

.sidenav-botonera {
  margin-left: 90px; }
