.cards-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%; }
  .cards-wrapper .card {
    width: 15rem;
    text-align: center; }
    .cards-wrapper .card .header-wrapper {
      margin: 0 auto; }
    .cards-wrapper .card .card-icon {
      font-size: 5rem; }
    .cards-wrapper .card .card-header {
      display: flex;
      align-items: center;
      min-height: 250px; }
      .cards-wrapper .card .card-header .card-title {
        margin-bottom: 0;
        font-weight: bold;
        margin-left: 0; }
    .cards-wrapper .card .card-text {
      min-height: 6rem; }
    .cards-wrapper .card .btn {
      width: 90%; }
  @media only screen and (min-width: 900px) {
    .cards-wrapper {
      flex-direction: row; } }
  @media only screen and (max-width: 1024px) {
    .cards-wrapper .card .card-text {
      min-height: 8rem; } }
  @media only screen and (max-width: 780px) {
    .cards-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto; }
      .cards-wrapper .card {
        margin-bottom: 2rem;
        width: 20rem; } }
