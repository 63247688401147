.home .home-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  min-height: 5rem;
  margin-top: 1rem; }

.home .tabla-crear-pregunta {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 36px;
  text-align: center;
  border-collapse: collapse;
  width: 100%; }
  .home .tabla-crear-pregunta th,
  .home .tabla-crear-pregunta td {
    text-align: left;
    padding: 8px; }
  .home .tabla-crear-pregunta tr:nth-child(even) {
    background-color: #f2f2f2; }
  .home .tabla-crear-pregunta th {
    background-color: #4caf50;
    color: white; }

.home .titulo-documentos {
  display: flex;
  justify-content: center; }

.home .tabs-container-ocr {
  display: flex;
  justify-content: space-between; }

.home .tab {
  min-width: unset;
  padding: 12px 24px;
  font-weight: 600;
  font-size: 14px;
  color: #657786;
  transition: color 0.3s; }

.home .tab.Mui-selected {
  color: #1DA1F2; }

.home .tab:hover {
  color: #1DA1F2; }

.home .tabWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center; }
