.cards-wrapper-planes {
  display: flex;
  justify-content: space-around;
  width: 100%; }
  .cards-wrapper-planes .card-planes {
    width: 1000px;
    text-align: center;
    border: 1px solid #7D60F5;
    margin: 20px; }
    .cards-wrapper-planes .card-planes .header-wrapper-planes {
      margin: 0 auto;
      color: blue; }
    .cards-wrapper-planes .card-planes .card-icon {
      font-size: 5rem; }
    .cards-wrapper-planes .card-planes .card-header {
      display: flex;
      align-items: center;
      min-height: 250px; }
      .cards-wrapper-planes .card-planes .card-header .card-title {
        margin-bottom: 0;
        font-weight: bold;
        margin-left: 0;
        margin-top: 20px;
        color: #3B86FF;
        font-size: 36px; }
      .cards-wrapper-planes .card-planes .card-header .price {
        font-size: 46px; }
    .cards-wrapper-planes .card-planes .card-text {
      min-height: 6rem; }
    .cards-wrapper-planes .card-planes .btn {
      width: 90%; }
    .cards-wrapper-planes .card-planes .accordion .card:first-of-type {
      border-bottom: 1px solid;
      border-bottom-right-radius: 0; }
  @media only screen and (min-width: 900px) {
    .cards-wrapper-planes {
      flex-direction: row; } }
  @media only screen and (max-width: 1024px) {
    .cards-wrapper-planes .card .card-text {
      min-height: 8rem; } }
  @media only screen and (max-width: 780px) {
    .cards-wrapper-planes {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto; }
      .cards-wrapper-planes .card {
        margin-bottom: 2rem;
        width: 20rem; } }
  .cards-wrapper-planes .mi-plan-actual {
    display: block; }
